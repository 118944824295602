import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/vincepicone/Programming/Work/carbon-website/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ComponentCode = makeShortcode("ComponentCode");
const ComponentDocs = makeShortcode("ComponentDocs");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "slider"
    }}>{`Slider`}</h2>
    <ComponentCode name="Slider" component="slider" variation="slider" hasReactVersion="slider--default" hasLightVersion hasVueVersion="slider--default" hasAngularVersion="?path=/story/slider--basic" codepen="zXqozN" mdxType="ComponentCode"></ComponentCode>
    <h2 {...{
      "id": "slider-disabled"
    }}>{`Slider disabled`}</h2>
    <ComponentCode name="Slider disabled" component="slider" variation="slider--disabled" hasReactVersion="slider--default" hasVueVersion="slider--default&knob-disabled=true" hasAngularVersion="?path=/story/slider--basic&knob-disabled=true" codepen="qwZqPV" mdxType="ComponentCode"></ComponentCode>
    <h2 {...{
      "id": "documentation"
    }}>{`Documentation`}</h2>
    <ComponentDocs component="slider" mdxType="ComponentDocs"></ComponentDocs>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      